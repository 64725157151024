const colors = {
  black: '#000000',
  blue: '#0a3067',
  brightBlue: '#6a9be1',
  buttonBlueActive: '#406dad',
  buttonRed: '#e5000f',
  buttonRedActive: '#b3000c',
  buttonSuccess: '#00873a',
  darkBlue: '#051b3c',
  gradientBrightRed: '#a6000b',
  lightBlue: '#25549c',
  lightGrey: '#e3e3e3',
  red: '#ff2735',
  unavailbleRedCircle: '#5a2846',
  white: '#ffffff',
  yellow: '#FFE900',
};

/**
 * Returns a color from the colors object based on the id
 * @param  {string} id - Identifier for the color in the configuration map. Default: white
 * @returns {string} Resulting color from configuration map
 * @example color: ${getColor('white')};
 */
const getColor = (id = 'white') => {
  if (!Object.prototype.hasOwnProperty.call(colors, id)) {
    throw new Error(`Color ${id} not known`);
  }

  return colors[id];
};

/**
 * Returns a color from the colors object based on the id as RGBA
 * @param  {string} id - Identifier for the color in the configuration map. Default: white
 * @param  {number} alpha - Alpha value for the color. Default: 1
 * @returns {string} Resulting color from configuration map
 * @example background-color: ${getColorRGBA('white', 0.5)};
 */
const getColorRGBA = (id = 'white', alpha = 1) => {
  const hex = getColor(id);
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export { getColor, getColorRGBA };
