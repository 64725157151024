/* eslint-disable sort-keys */
const breakpoints = {
  s: '375px',
  m: '700px',
  l: '1020px',
};

/**
 * Simple CSS MediaQuery.
 * Expects a breakpoint (string or number) and min or max string (default min).
 * @param {string} mediaQuery - Identifier for the breakpoint in the configuration file
 * @param {string} minMax - Default: min. String for the media-query type
 * @returns {string} Media query definition
 * @example ${mq('m', 'max')} { css styles go here };
 */
export default function mq(id, minMax = 'min') {
  if (!Object.prototype.hasOwnProperty.call(breakpoints, id)) {
    throw new Error(`Breakpoint ${id} not known`);
  }

  return `
    @media (${minMax}-width: ${breakpoints[id]}) 
  `;
}
