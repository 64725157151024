/* eslint-disable sort-keys */
import mq from './breakpoints';
import { px2rem } from '../tools';

const fontFamilies = {
  default: 'd-dinregular, Helvetica, sans-serif',
  bold: 'd-dindin-bold, Helvetica, sans-serif'
};

const textTypes = {
  h1: {
    fontSize: '22px',
    lineHeight: '22px',
    m: {
      fontSize: '42px',
      lineHeight: '42px',
    },
  },
  h2: {
    fontSize: '24px',
    lineHeight: '30px',
    m: {
      fontSize: '28px',
      lineHeight: '36px',
    },
  },
  c13: {
    fontSize: '16px',
    lineHeight: '20px',
    m: {
      fontSize: '18px',
      lineHeight: '22px',      
    }
  },
};

/**
 * Returns text type from textTypes object
 * @param {string} type - text type
 * @param {string} fontFamily - font-family defined in settings. Default: "default"
 * @returns {string} text type styles defined in settings
 * @example ${getTextType('copy', 'default')};
 */
function getTextType(type, fontFamily = 'default') {
  return `
    font-size: ${px2rem(textTypes[type].fontSize)};
    line-height:  ${`${px2rem(textTypes[type].lineHeight)}`};
    font-family: ${fontFamilies[fontFamily]};

  ${
    textTypes[type].m
      ? `${mq('m')} {
      font-size: ${px2rem(textTypes[type].m.fontSize)};
      line-height:  ${px2rem(textTypes[type].m.lineHeight)};
    }`
      : ''
  }
  `;
}

export { fontFamilies, getTextType };
