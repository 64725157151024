/**
 * CSS to reset button styling.
 * @returns {string}
 * @example ${btnReset()}
 */
export default function btnReset() {
  return `
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }
  `;
}
