/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sort-keys */
import React from 'react';
import styled from "styled-components"
import { mq } from './index';
import { px2rem } from '../tools/index';

const maxWidth = '1020px';
const grid = {
  gutters: {
    s: 12,
    m: 30,
    l: 30,
  },
  columns: {
    s: 6,
    m: 6,
    l: 12,
  },
  debugColors: '#cceaf8',
};

/**
 * Sets max-width and centers component
 * @param  {string} max - max-width value, defaults to maxWidth configuration value
 * @return {objects} Resulting styling object
 * @example ${maxContentWidth('1200px')}; returns 'max-width: 1200px; margin-left: auto; margin-right: auto;'
 */
export function maxContentWidth(max = maxWidth) {
  if (typeof max !== 'string') {
    throw new Error('max must be a string');
  }

  return {
    maxWidth: px2rem(max),
    marginLeft: 'auto',
    marginRight: 'auto',
  };
}

/**
 * Get gutter calculation
 * @param {string} columns - containing the columns quantity
 * @param {string} spread - defines the spread of the columns. You can pass "normal", "wide", "spread", "full", "overlap". Default: "normal"
 * @param {string} parentColumns - defines the columns of the parent container. Default: 12
 * @returns {string} Returns grid calculation and sets styles
 * @example ${getGridSpan(6, 'spread', 9)};
 */
function getGridSpan(key, columns, spread = 'normal', parentColumns) {
  if (parentColumns > grid.columns[key]) {
    throw Error(
      `Maximum parent columns for this breakpoint (${key}) are ${grid.columns[key]}`
    );
  }

  const newParentColumns = parentColumns || grid.columns[key];

  if (columns > grid.columns[key]) {
    throw Error(
      `Maximum columns for this breakpoint (${key}) are ${grid.columns[key]}`
    );
  }

  let spreadSize;

  if (spread === 'normal') {
    spreadSize = `${grid.gutters[key] * (columns - 1)}px`;
  } else if (spread === 'wide') {
    spreadSize = `${grid.gutters[key] * (columns - 1) +
      grid.gutters[key] / 2}px`;
  } else if (spread === 'spread') {
    spreadSize = `${grid.gutters[key] * (columns - 1) + grid.gutters[key]}px`;
  } else if (spread === 'full') {
    spreadSize = `${grid.gutters[key] * (columns - 1) +
      grid.gutters[key] * 2}px`;
  } else if (spread === 'overlap') {
    spreadSize = `${grid.gutters[key] * (columns - 1) + 12}px`;
  } else {
    throw Error(
      'You need to pass "normal", "wide", "spread", "full" as an argument'
    );
  }

  const innerGaps = `${(newParentColumns - 1) * grid.gutters[key]}px`;

  return `
    calc(${spreadSize} + (100% - ${innerGaps}) / ${newParentColumns} * ${columns})
  `;
}

/**
 * Get gutter calculation
 * @param {string} property - css property.
 * @param {object} obj - object with different media queries and settings
 * @returns {string} Returns full grid calculation with media queries and sets styles
 * @example ${getGridSpans('width', { s: 12, m: [8, 'spread', 10], l: [6, 'spread', 8] })};
 */
export function getGridSpans(property, obj) {
  if (typeof property !== 'string') {
    throw Error('Property needs to be a string');
  }

  if (typeof obj !== 'object') {
    throw Error(
      'obj needs to be an object with media queries as keys, eg. { s: 12, m: 10, l: 8 }'
    );
  }

  let string = '';

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'number' && obj[key] === grid.columns[key]) {
      string +=
        key === 's'
          ? `${property}: 100%;`
          : `${mq(key)} { ${property}: 100%; };`;

      return;
    }

    if (typeof obj[key] === 'number' && obj[key] === 0) {
      string +=
        key === 's' ? `${property}: 0;` : `${mq(key)} { ${property}: 0; };`;

      return;
    }

    if (typeof obj[key] === 'number') {
      string +=
        key === 's'
          ? `${property}: ${getGridSpan(key, obj[key])};`
          : `${mq(key)} { ${property}: ${getGridSpan(key, obj[key])}; };`;

      return;
    }

    if (typeof obj[key] === 'object') {
      string +=
        key === 's'
          ? `${property}: ${getGridSpan(
              key,
              obj[key][0],
              obj[key][1],
              obj[key][2]
            )};`
          : `${mq(key)} { ${property}: ${getGridSpan(
              key,
              obj[key][0],
              obj[key][1],
              obj[key][2]
            )}; };`;
    }
  });

  return string;
}

export function getGridGutter(key) {
  return `${grid.gutters[key]}px`;
}

/**
 * Displays a simple debug Grid to show columns and gaps
 * @returns {string} Returns simple debug grid (just use in development)
 * @example <DebugGrid />;
 */
export class DebugGrid extends React.Component {
  static createColumns() {
    const columns = [];

    for (let i = 0; i < grid.columns.l; i += 1) {
      // eslint-disable-next-line react/jsx-filename-extension
      columns.push(<Column key={i} />);
    }

    return columns;
  }

  constructor(props) {
    super(props);
    this.state = {
      hidden: props.hidden,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    if (process && process.browser) {
      document.addEventListener('keydown', event => this.handleKeyDown(event));
    }
  }

  componentWillUnmount() {
    if (process && process.browser) {
      document.removeEventListener('keydown', event =>
        this.handleKeyDown(event)
      );
    }
  }

  handleKeyDown(event) {
    const { hidden } = this.state;

    if (event.code === 'KeyG') {
      this.setState({
        hidden: !hidden,
      });
    }
  }

  render() {
    const { hidden } = this.state;

    return (
      <DebugGridContainer className={hidden ? 'hidden' : ''}>
        {DebugGrid.createColumns()}
      </DebugGridContainer>
    );
  }
}

const DebugGridContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-top: 1px solid #ff0000;
  border-bottom: 1px solid #ff0000;
  white-space: nowrap;

  &.hidden {
    display: none;
  }
`;

const Column = styled.span`
  position: relative;
  opacity: 0.5;
  display: inline-block;
  width: calc(
    (100% - ${(grid.columns.s - 1) * grid.gutters.s}px) / ${grid.columns.s}
  );

  height: 100%;
  box-sizing: border-box;
  margin-right: ${grid.gutters.s}px;
  border-right: 1px solid #ff0000;
  border-left: 1px solid #ff0000;

  background: ${grid.debugColors ? grid.debugColors : 'pink'};

  &:nth-last-child(-n + 8) {
    display: inline-block;
  }

  &:nth-last-child(-n + 6) {
    display: none;
  }

  @media (min-width: 700px) {
    width: calc(
      (100% - ${(grid.columns.m - 1) * grid.gutters.m}px) / ${grid.columns.m}
    );
    margin-right: ${grid.gutters.m}px;
  }

  @media (min-width: 1020px) {
    width: calc(
      (100% - ${(grid.columns.l - 1) * grid.gutters.l}px) / ${grid.columns.l}
    );
    margin-right: ${grid.gutters.l}px;

    &:nth-last-child(-n + 6) {
      display: inline-block;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
