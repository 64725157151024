/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import GlobalStyle from '@stylesheets/global'
import { DebugGrid } from '@stylesheets/settings' 

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [isCookieLayerVisible, setCookieLayerVisible] = useState(true);

  const isCookieSet = () => {
    if (typeof window !== 'undefined') {
      const name = 'gatsby-gdpr-google-analytics';
      var value = "; " + document.cookie;
      return value.indexOf('gatsby-gdpr-google-analytics') <= 0;
    }
  }

  const setCookie = (value) => {
    // gatsby-gdpr-google-analytics
    if (typeof window !== 'undefined') {
      document.cookie = `gatsby-gdpr-google-analytics=${value}; expires=Fri, 31 Dec 2024 23:59:59 GMT`;
      if (value === 'true') {
        window.location.reload();
      }
    }
  };

  return (
    <>
      <GlobalStyle />
      {/* Header */}
      <main>
        {children}
        {!isCookieSet() || isCookieLayerVisible &&
          <div style={{position: 'fixed', display: 'flex', flexDirection: 'column', width: '100%', 'bottom': 0, backgroundColor: '#000000', padding: '20px', justifyContent: 'space-between'}}>
            <p style={{color: '#fff', textAlign: 'center'}}>
              Um anonyme Nutzungsdaten über Google Analytics zu erheben verwenden wir auf dieser Seite Cookies.<br/>Erfahren Sie hier mehr: <a style={{textDecoration: 'underline'}} href="/terms">Datenschutzerklärung</a>.
            </p>
            <div style={{display: 'flex', margin: '0 auto', width: '200px', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px'}}>
              <button onClick={() => {setCookie('true'); setCookieLayerVisible(false)}} style={{color: '#fff', textDecoration: 'underline', fontSize: '18px'}}>Akzeptieren</button>
              <button onClick={() => {setCookie('false'); setCookieLayerVisible(false)}} style={{color: '#fff', textDecoration: 'underline', fontSize: '18px'}}>Ablehnen</button>
            </div>
          </div>
        }
      </main>
      {/* Footer */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
